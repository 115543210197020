const initialState = {
  list: [],
  noData: 
    {
      order: "",
      issued: "",
      consultant: "",
      consultantAddress: {
        consultantAddressId: "",
        countryId: "",
        countryName: "",
        stateId: "",
        stateName: "",
        cityId: "",
        cityName: "",
        address: "",
        postcode: "",
        fullAddress: "",
        aboutMe: null,
        dbsrefNumber: null,
        latitude: 0,
        urlId: "",
        longitude: 0,
        isDefaultAddress: undefined,
      },
      customer: "",
      customerAddress: {
        customerId: "",
        customerAddressId: "",
        countryId: "",
        countryName: "",
        stateId: "",
        stateName: "",
        cityId: "",
        cityName: "",
        address: "",
        addressLine1: "",
        addressLine2: "",
        addressTitle: "",
        zipCode: 0,
        latitude: 0,
        longitude: 0,
        isDefaultAddress: undefined,
      },
      description: "",
      quantity: 0,
      total: 0,
      subTotal: 0,
      discount: 0,
      taxAmount: 0,
      totalAmount: 0,
    },
};

export const TransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TRANSACTION_DATA":
      return {
        ...state,
        list: action.payload,
      };
    case "GET_BY_TRANSACTION_DATA":
      return {
        ...state,
        noData: action.payload,
      };
    case "REMOVE_TRANSACTION":
      return {
        ...state,
        noData: {
          order: "",
          issued: "",
          consultant: "",
          consultantAddress: {
            consultantAddressId: "",
            countryId: "",
            countryName: "",
            stateId: "",
            stateName: "",
            cityId: "",
            cityName: "",
            address: "",
            postcode: "",
            fullAddress: "",
            aboutMe: null,
            dbsrefNumber: null,
            latitude: 0,
            urlId: "",
            longitude: 0,
            isDefaultAddress: undefined,
          },
          customer: "",
          customerAddress: {
            customerId: "",
            customerAddressId: "",
            countryId: "",
            countryName: "",
            stateId: "",
            stateName: "",
            cityId: "",
            cityName: "",
            address: "",
            addressLine1: "",
            addressLine2: "",
            addressTitle: "",
            zipCode: 0,
            latitude: 0,
            longitude: 0,
            isDefaultAddress: undefined,
          },
          description: "",
          quantity: 0,
          total: 0,
          subTotal: 0,
          discount: 0,
          taxAmount: 0,
          totalAmount: 0,
        },
      };
    default:
      return state;
  }
};
