const initialState = {
    data: [],
};

export const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_SUBSCRIPTION_DATA":
            return {
                ...state,
                data: action.payload
            };
      default:
        return state;
    }   
};
