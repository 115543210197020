import { combineReducers } from "redux";
import { AppointmentReducer } from "./AppointmentReducer";
import ConsultantReducer from "./ConsultantReducer";
import UserReducer from "./UserReducer";
import { subscriptionReducer } from "./SubscriptionReducer";
import { reviewReducer } from "./ReviewsReducer";
import { contactReducer } from "./ContactUsReducer";
import { CustomerReducer } from "./CustomerReducer";
import { TransactionReducer } from "./TransactionReducer";

const initialState = {
  sidebarShow: "responsive",
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  appointmentData: AppointmentReducer,
  userData: UserReducer,
  ConsultantData: ConsultantReducer,
  subsctptionData: subscriptionReducer,
  reviewData: reviewReducer,
  contactData: contactReducer,
  sidebarShow: changeState,
  transactionReducer:TransactionReducer,
  customerData: CustomerReducer,
});

export default rootReducer;
