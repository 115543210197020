const initialState = {
  data: []
}

export const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_REVIEWS_DATA':
      return {
        ...state,
        data: action.payload
      }
    default:
      return state
  }
}
