const appointmentInitState = {
  appointments: []
}

export const AppointmentReducer = (state = appointmentInitState, action) => {
  switch (action.type) {
    case 'GET_APPOINTMENT_DATA': {
      const appointments = action.payload
      return { ...state, appointments }
    }

    default:
      return { ...state }
  }
}
