const initialState = {
  currentPage: "",
  pageSize: "",
  totalPages: "",
  consultants: [],
};

const ConsultantReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONSULTANT_DATA":
      const consultants = action.payload.items;
      const currentPage = action.payload.currentPage;
      const pageSize = action.payload.pageSize;
      const totalPages = action.payload.totalPages;
      return { ...state, consultants, currentPage, pageSize, totalPages };

    default:
      return state;
  }
};

export default ConsultantReducer;
