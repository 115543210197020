const initialState = {
  contacts: []
}

export const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CONTACTUS_DATA':
      return { ...state, contacts: action.payload }
    default:
      return state
  }
}
